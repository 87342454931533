<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/integralDetailInfo">用户积分统计管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="活动名称" prop="activityName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.activityName"
        ></el-input>
      </el-form-item>
      <el-form-item label="学校" prop="companyId">
        <el-select-tree
          size="mini"
          :props="props"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree
        >&nbsp;
        <el-checkbox v-model="queryModel.subordinate"
          >是否包含下级班级</el-checkbox
        >
      </el-form-item>
      <el-form-item label="用户名称" prop="personName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.personName"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <!-- <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="downloadXls"
        >导出数据</el-button
      > -->
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column
      ><el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="activityName"
        label="活动名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="schoolName"
        label="学校"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="className"
        label="班级"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="personId"
        label="用户编号"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="personName"
        label="用户名称"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="integralNum"
        label="获得的积分数"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="activityReward"
        label="活动奖励"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="获得时间"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import integralDetailInfoApi from "@/api/integral/integralDetailInfo";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "integralDetailInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        personName: "",
        activityName: "",
        companyId:"",
        subordinate: false,
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      downloadLoading: false,
      loadingText: "",
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
    this.loadTree();
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personName", self.queryModel.personName);
      formData.append("activityName", self.queryModel.activityName);
      formData.append("companyId", self.queryModel.companyId);
      formData.append("subordinate", self.queryModel.subordinate);
      
      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      integralDetailInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          integralDetailInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        integralDetailInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },

    downloadXls() {
      //导出
      // var self = this;
      // self.downloadLoading = true;
      // self.loading = true;
      // self.loadingText = "数据导出中，请稍后...";
      // var formData = new FormData();
      // formData.append("pageIndex", self.pageIndex);
      // formData.append("pageSize", self.pageSize);
      // formData.append("personName", self.queryModel.personName);
      // formData.append("goodsName", self.queryModel.goodsName);
      // if (this.field != null) {
      //   formData.append("field", this.field);
      // }
      // if (this.direction != null) {
      //   formData.append("direction", this.direction);
      // }
      // integralDetailInfoApi.exportXls(formData).then(function (response) {
      //   var jsonData = response.data;
      //   self.downloadLoading = false;
      //   self.loading = false;
      //   if (jsonData.result) {
      //     //下载有错误信息提示的报表
      //     self.$message({
      //       showClose: true,
      //       dangerouslyUseHTMLString: true,
      //       message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
      //       duration: 60000,
      //       type: "success",
      //     });
      //   } else {
      //     self.$message.error(jsonData.message);
      //   }
      // });
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>